import React from 'react'
import productBanner from '../assets/product-banner.jpg'
import productImageFirst from '../assets/product-image-first.png'
import { Col, Container, Row } from 'react-bootstrap'
import '../styles/product/product.scss'

function Product() {
  return (
    <div className='product-component'>
      <div className='image-main'>
        <img className='about-img' src={productBanner} alt='productBanner' />
        <div className='overlay'></div>
        <p className='content'>NLiten Data Farmer is an AI infused Next-Gen data ecosystem</p>
      </div>
      <Container className='product-content'>
        <Row style={{ height: "100%", backgroundColor: "#e0f1f1" }}>
          <Col className='col' style={{ height: "100%" }}>
            <p>
              NLiten Data Farmer will empower clients, end customers, and AI models to help make responsible business decisions with trustable and authentic data that is democratized, and secure. This distributed data platform wraps rich metadata to make data intelligent and continuously enriches itself with rules as required by users, driven by evolving business needs in today’s fast paced corporate world.
            </p>
          </Col>
        </Row>
        <Row style={{ height: "100%" }}>
          <Col className='col col-image' style={{ height: "100%" }}>
            <img src={productImageFirst} alt={productImageFirst} className='image-first' />
          </Col>
        </Row>
        <Row style={{ height: "100%", backgroundColor: "#e0f1f1" }}>
          <Col className='col' style={{ height: "100%" }}>
            <p>
              Data can exist wherever it is but will be provisioned to fit the needs of organizational and customer end users with a next generation AI-driven transformational product that is meta-data driven and polymorphs dynamically to any data structure needed at the time of consumption in a “state of the art” secure platform. Its capabilities include:
            </p>
          </Col>
        </Row>
        <Row style={{ height: "100%", backgroundColor: "#e0f1f1" }}>
          <Col className='col col-3' style={{ height: "100%" }}>
            <ol>
              <li>Real-time connectivity to Source data (Structured, Semi structured and Unstructured)</li>
              <li>Elimination of Date Pipeline - No ETL, and hence lower cost of build, implementation, run/manage, and licensing</li>
              <li>Automatic capture of changes to source structures</li>
              <li>Integration of entire source systems as opposed to selected tables/files, thus eliminating the need for new data pipelines for evolving consumption needs</li>
              <li>Data quality, MDM and Data governance institutionalized as part of the product, eliminating the need for separate DQ, DG & MDM tools</li>
              <li>Late binding at the point of consumption eliminates the need for layered architecture</li>
              <li>Polymorphic capability of the Harvester enables data provisioning in any format</li>
              <li>High Performance enabled by on-demand spawning of intelligent data and rules objects to meet demand spikes</li>
              <li>Record-level blockchain security in a distributed environment significantly reduces vulnerabilities and proactively protecting against data breaches</li>
              <li>Infinitely flexible distributed data architecture to accommodate unknown scenarios, rapid changes and the shifting needs of business users</li>
              <li>Contextual search capability enabled by rich and intelligent metadata</li>
            </ol>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Product