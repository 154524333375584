import React from 'react'
import consultingBanner from '../assets/consulting-banner.png'
import { Col, Container, Row } from 'react-bootstrap'
import '../styles/consulting/consulting.scss'

function Consulting() {
  return (
    <div className='consulting-component'>
      <div className='image-main'>
        <img className='about-img' src={consultingBanner} alt='consultingBanner' />
        <div className='overlay'></div>
        <p className='content'>
          Creating undiscovered value to clients with a startup culture of innovation at low cost, in the Data & AI space with break-through tools, accelerators and products.
        </p>
      </div>
      <Container className='consulting-content'>
        <Row style={{ height: "100%", backgroundColor: "#fef3df" }}>
          <Col className='col' style={{ height: "100%" }}>
            <p>
              Every business user and end customer requires a different view of their world to analyze and make decisions, just as they have different custom orders of their favorite drinks at the coffee shop. And that custom order can change with their moods, time of the day, season or changes to their outlook in life. Data platforms have struggled with this phenomenon of changing needs of users in three different ways: (1) The front end needs to be extensively customized frequently, (2) New transformational rules have to be applied or (3) New data is required to be captured from either existing or new sources. All this takes time, but in today's fast-paced business world, customer expectations are “Know me” and “Give me the data I need now”.
            </p>
          </Col>
        </Row>
        <Row style={{ height: "100%" }}>
          <Col className='col col-image' style={{ height: "100%" }}>
            <p>
              With our innovative tools and accelerators, we address the above industry problem. The underpinning to this is our groundbreaking data architecture of a distributed and intelligent data ecosystem. We meet clients where they are in their data & AI Digital transformation journey, leveraging the investments made thus far and seamlessly plug in to their existing ecosystems to leapfrog to a new state-of-the-art data ecosystem.
            </p>
          </Col>
        </Row>
        <Row style={{ height: "100%", backgroundColor: "#fef3df" }}>
          <Col className='col' style={{ height: "100%" }}>
            <p>We collaborate and support our clients to:</p>
            <ol>
              <li>Solve specific unsolved data issues with innovative AI/ML infused solutions.</li>
              <li>Design target state architecture that meets the business needs of today and tomorrow.</li>
              <li>
                Formulate and execute the roadmap cost effectively to achieve the end state data architecture with innovative accelerators, highly skilled data and AI specialists and experienced thought leadership.
              </li>
              <li>Implement with speed-to-market and empower users with self-service and self-learning capabilities</li>
              <li>Manage and run the ecosystem with skilled DevOps teams that continuously enhance user experience and system resilience.</li>
            </ol>
          </Col>
        </Row>
        <Row style={{ height: "100%", }}>
          <Col className='col' style={{ height: "100%" }}>
            <p>
              In addition, NLitenData provides strategy, design, implementation and run/manage services for traditional data and AI ecosystems
            </p>
            <ul>
              <li>Align data and AI/ML strategy with business architecture</li>
              <li>Perform data discovery, target state architecture design, and roadmap </li>
              <li>Formulate AI governance strategy and enable implementation</li>
              <li>Implement Lake houses on cloud, hybrid environments, and on-prem</li>
              <li>Enable large-scale data integrations for analytical, data science and AI needs</li>
              <li>Create real-time dashboards, advanced analytics, AI/ML models, and custom UIs for enhanced user experience</li>
              <li>
                Execute data migration to modern data ecosystems from legacy DW/DL systems and sources like mainframe and legacy applications
              </li>
              <li>Implement data governance, master data management, and data quality initiatives</li>
              <li>
                Infuse AI into existing ecosystems for business automation, client productivity, and data management, including MDM and data quality
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Consulting
