import './App.css';
import PageRoute from './routes/page-route';

function App() {
  return (
    <>
      <PageRoute />
    </>
  );
}

export default App;
