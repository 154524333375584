import React from 'react'
import aboutImage from '../assets/about.jpg'
import AboutCard from '../components/about-page/about-card'
import '../styles/about/about.scss'

function About() {
  return (
    <div className='about-component'>
      <div className='image-main'>
        <img className='about-img' src={aboutImage} alt='About' />
        <div className='overlay'></div>
        <p className='content'>About NLitenData</p>
      </div>
      <div className='body-main'>
        <p className='content-one'>
          NLitenData is a data and AI Product and Consulting company, dedicated to creating value and business outcomes for our clients with innovative solutions and breakthrough technologies. Its two founders were Partners and Senior Executives across IBM, Accenture, and Deloitte with more then 25+ years of experience in designing and implementing large scale solutions including data, analytics and AI across industries such as banking, capital markets, insurance, utilities, oil & gas, retail, healthcare and life sciences.
        </p>
        <p className='content-two'>
          We recognize that people are our key assets, with quality, integrity, authenticity, and responsibility being our core values. We meet clients where they are in their data and AI journey and provide services that accelerate business outcomes, while optimizing their investments with innovation and creativity.
        </p>
        <p className='content-three'>
          Our product is a groundbreaking next generation distributed data platform that can deliver secure, reliable, real-time, governed, and trusted data on demand, greatly accelerating our clients’ Data & AI Digital transformation journey.
        </p>
        <p className='content-three'>
          Our staffing services covers a variety of technology implementations across data, enterprise applications, cloud migrations, full stack development, and infrastructure services.
        </p>
      </div>
      <div className='about-card-main'>
        <AboutCard />
      </div>
    </div>
  )
}

export default About