import React from 'react'
import Banner from '../components/home-page/banner'
import HomeAbout from '../components/home-page/home-about'
import HomeConsulting from '../components/home-page/home-consulting'
import HomeStaffing from '../components/home-page/home-staffing'

function Home() {
  return (
    <div>
        <Banner/>
        <HomeAbout/>
        <HomeConsulting/>
        <HomeStaffing/>
    </div>
  )
}

export default Home