import React from 'react'
import { ReactComponent as Logo } from '../assets/logo.svg'
import { Col, Container, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import '../styles/footer/footer.scss'

function Footer() {
    const navigate = useNavigate();

    function handleHeaderNav(params) {
        navigate(`/${params}`)
        window.scrollTo(0, 0);
    }

    return (
        <div className='footer-component'>
            <Container style={{ padding: "0px", maxWidth: "100%" }}>
                <Row className='row-one'>
                    <Col className='col-1' lg={4} md={4} sm={4} xs={12} xxs={12}>
                        <div className='first-col'>
                            <Logo
                                style={{ width: "125px", height: "70px", fill: 'white', cursor: "pointer" }}
                                className='footer-logo'
                                onClick={() => { handleHeaderNav('home') }}
                            />
                            <a
                                href="https://www.google.com/maps/dir//texas,%20usa"
                                target="_blank"
                                rel="noopener noreferrer"
                                className='address-link'
                            >
                                <p className='addres'>Texas, USA.</p>
                                {/* <p className='addres'></p> */}
                            </a>
                        </div>
                    </Col>
                    <Col className='col-2' lg={4} md={4} sm={4} xs={12} xxs={12}>
                        <div className='second-col'>
                            <p className='heading'>Quick Links</p>
                            <p className='link' onClick={() => { handleHeaderNav('home') }}>Home</p>
                            <p className='link' onClick={() => { handleHeaderNav('product') }}>Product</p>
                            <p className='link' onClick={() => { handleHeaderNav('consulting') }}>Consulting</p>
                            <p className='link' onClick={() => { handleHeaderNav('industries') }}>Industries</p>
                            <p className='link' onClick={() => { handleHeaderNav('team') }}>Team</p>
                            <p className='link' onClick={() => { handleHeaderNav('about') }}>About</p>
                            {/* <p className='link' onClick={() => { }}>Contact</p> */}
                        </div>
                    </Col>
                    <Col className='col-3' lg={4} md={4} sm={4} xs={12} xxs={12}>
                        <div className='therd-col'>
                            <p className='head'>Contact Us</p>
                            <div className='gmail-main'>
                                <p>Email:</p>
                                <a href="mailto:info@NLitenData.com" className='email-link'>info@NLitenData.com</a>
                            </div>
                            {/* <script
                                type="text/javascript"
                                src="https://www.free-counters.org/count/g6qa"
                            ></script>
                            <a href="http://www.freevisitorcounters.com">Counters</a>
                            <script
                                type="text/javascript"
                                src="https://www.whomania.com/ctr?id=e3208e4d09b413cd83917bbc9532b025b4e86222"
                            ></script>

                            <a href='http://www.freevisitorcounters.com'>Counters</a> <script type='text/javascript' src='https://www.freevisitorcounters.com/auth.php?id=7836dd52fb239283988cc683eb4be5e09da8a5fc'></script>
                            <script type="text/javascript" src="https://www.freevisitorcounters.com/en/home/counter/1228372/t/0"></script> */}
                            {/* <div className='phone-main'>
                                <p>Phone:</p>
                                <p>(713)730-2727</p>
                            </div> */}
                        </div>
                    </Col>
                </Row>
                <Row className='row-two'>
                    <Col style={{ padding: '0px' }}>
                        <p className='copy-main'>© 2024 Copyright: <span onClick={() => { handleHeaderNav('home') }}>NLitenData.LLC</span></p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Footer