import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import visionImage from '../../assets/vision-image.jpg'
import missionImage from '../../assets/mission-image.jpg'
import values from '../../assets/values.jpg'
import '../../styles/about/about-card.scss'

function AboutCard() {
    return (
        <Container className='about-card-component' >
            <Row className='first-row'>
                <Col xl={6} lg={6} md={6} sm={12} xs={12} xxs={12} className='col' >
                    <Card className='first-card'>
                        <Card.Body>
                            <img src={visionImage} alt='visionImage' className='first-image' />
                            <p className='heading'>Vision</p>
                            <p className='body'>
                                To lead the future of data innovation through the best data platform on planet earth and provide services that empower our clients to transcend unsolved data challenges of today and tomorrow and harness the transformative power of AI.
                            </p>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xl={6} lg={6} md={6} sm={12} xs={12} xxs={12} className='col'>
                    <Card className='first-card' style={{ backgroundColor: "#e6ebe4" }}>
                        <Card.Body>
                            <img src={missionImage} alt='missionImage' className='first-image' />
                            <p className='heading'>Mission</p>
                            <p className='body'>
                                To create a futuristic meta-data driven intelligent and secure data platform with services that rapidly creates value to our clients’ AI and analytics journey to enable breakthrough innovation.
                            </p>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xl={12} lg={12} md={12} sm={12} xs={12} xxs={12} className='col'>
                    <Card className='second-card'
                        style={{ backgroundColor: "#e0f1f1" }}
                    >
                        <Card.Body style={{ display: "flex", width: "100%" }}>
                            <div className='image-main'>
                                <img src={values} alt='values' className='values-image' />
                            </div>
                            <div className='content-main'>
                                <p className='heading'>Values</p>
                                <p className='sub-heading'>Integrity in Everything We Do</p>
                                <p className='body'>
                                    "We hold ourselves to the highest ethical standards, ensuring honesty, transparency, and accountability in all our actions and decisions."
                                </p>
                                <p className='sub-heading'>Breakthrough Thinking to Innovate Beyond Current Technology Boundaries</p>
                                <p className='body'>
                                    "We challenge the status quo with creative and bold ideas, pushing the limits of what’s possible to deliver groundbreaking data and AI solutions that address unsolved business issues."
                                </p>
                                <p className='sub-heading'>Client Value and Responsibility at the core</p>
                                <p className='body' style={{ margin: "0px" }}>
                                    "We are committed to our clients' success, tailoring our efforts to meet their unique needs and consistently delivering value that drives their growth."
                                </p>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default AboutCard