import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import homeAbout from '../../assets/home-about.jpg'
import '../../styles/home-page/home-about.scss'

function HomeAbout() {
    return (
        <Container className='home-about-component'>
            <Row style={{ height: "100%" }}>
                <Col className='col' lg={6} md={12} sm={12} xs={12} xxs={12} style={{ height: "100%" }}>
                    <div className='left-side-image'>
                        <img src={homeAbout} alt='homeAbout' />
                    </div>
                </Col>
                <Col className='col' lg={6} md={12} sm={12} xs={12} xxs={12} style={{ height: "100%" }}>
                    <div className='right-side-image'>
                        <div className='content-main'>
                            <p className='heading'>NlitenData Farmer is a next generation, AI infused data platform</p>
                            <p className='content'>
                                Data can exist wherever it is but can be provisioned to meet the analytical and AI needs of business users and end customers with a meta-data driven and AI infused data platform that polymorphs dynamically to any data structure needed at the time of consumption without data pipelines, but with “state of the art” block chain data security.
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default HomeAbout