import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Layout from '../layouts/layout'
import About from '../pages/about'
import Home from '../pages/home'
import Team from '../pages/team'
import Product from '../pages/product'
import Consulting from '../pages/consulting'
import Industries from '../pages/industries'

function PageRoute() {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<Layout />}>
                    <Route path="/" element={<Home />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/team" element={<Team />} />
                    <Route path="/product" element={<Product />} />
                    <Route path="/consulting" element={<Consulting />} />
                    <Route path="/industries" element={<Industries />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default PageRoute