import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { ReactComponent as Logo } from '../assets/logo.svg'
import { useLocation, useNavigate } from 'react-router-dom';
import '../styles/header/header.scss'

function Header() {
  const [currentNav, setCurrentNav] = useState('home')
  const navigate = useNavigate();
  const location = useLocation();

  // Synchronize currentNav with the current route path
  useEffect(() => {
    const path = location.pathname.slice(1); // Get the path without the leading '/'
    setCurrentNav(path || 'home'); // If path is empty, default to 'home'
  }, [location.pathname]);

  function handleHeaderNav(params) {
    navigate(`/${params}`)
    setCurrentNav(params)
    window.scrollTo(0, 0);
  }

  return (
    <Navbar expand="lg" className="navbar-main">
      <Container fluid>
        <Navbar.Brand>
          <Logo
            style={{ width: "150px", height: "70px", fill: '#8d3d04', cursor: "pointer" }}
            onClick={() => { handleHeaderNav('home') }}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
          </Nav>
          <div className="menus-main">
            <Nav.Link
              className={currentNav === 'home' ? 'menus-active' : "nav-link"}
              onClick={() => { handleHeaderNav('home') }}
            >
              Home
            </Nav.Link>
            <Nav.Link
              className={currentNav === 'product' ? 'menus-active' : "nav-link"}
              onClick={() => { handleHeaderNav('product') }}
            >
              Product
            </Nav.Link>
            <Nav.Link
              className={currentNav === 'consulting' ? 'menus-active' : "nav-link"}
              onClick={() => { handleHeaderNav('consulting') }}
            >
              Consulting
            </Nav.Link>
            <Nav.Link
              className={currentNav === 'industries' ? 'menus-active' : "nav-link"}
            onClick={() => { handleHeaderNav('industries') }}
            >
              Industries
            </Nav.Link>
            <Nav.Link
              className={currentNav === 'team' ? 'menus-active' : "nav-link"}
              onClick={() => { handleHeaderNav('team') }}
            >
              Team
            </Nav.Link>
            <Nav.Link
              className={currentNav === 'about' ? 'menus-active' : "nav-link"}
              onClick={() => { handleHeaderNav('about') }}
            >
              About
            </Nav.Link>
            {/* <Nav.Link
              className={currentNav === 'contact' ? 'menus-active' : "nav-link"}
            // onClick={() => { handleHeaderNav('contact') }}
            >
              Contact
            </Nav.Link> */}
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;