import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import bannerImageOne from '../../assets/banner-image-1.jpg';
import bannerImageTwo from '../../assets/banner-image-2.jpg';
import bannerImageThree from '../../assets/banner-image-3.jpg';
import bannerImageFour from '../../assets/banner-image-4.jpg';
import '../../styles/home-page/banner.scss'

function Banner() {
    return (
        <div className="banner-container">
            <div className="banner-text">
                <h3>
                    NLitenData is a futuristic product and consulting company focused on break-through innovation in Data and AI solutions for clients globally across industries.
                </h3>
            </div>
            <Carousel className="carousel-background">
                <Carousel.Item style={{ height: "calc(100vh - 100px)" }}>
                    <div className="drk">
                        <img
                            src={bannerImageOne}
                            alt="bannerImageOne"
                            style={{ width: '100%', height: 'calc(100vh - 100px)', objectFit: 'cover' }}
                        />
                    </div>
                </Carousel.Item>
                <Carousel.Item style={{ height: "calc(100vh - 100px)" }}>
                    <div className="drk">
                        <img
                            src={bannerImageTwo}
                            alt="bannerImageTwo"
                            style={{ width: '100%', height: 'calc(100vh - 100px)', objectFit: 'cover' }}
                        />
                    </div>
                </Carousel.Item>
                {/* <Carousel.Item>
                    <div className='drk'>
                        <img
                            src={bannerImageThree}
                            alt="bannerImageThree"
                            style={{ width: '100%', height: 'calc(100vh - 100px)', objectFit: 'cover' }}
                        />
                    </div>
                </Carousel.Item> */}
                <Carousel.Item style={{ height: "calc(100vh - 100px)" }}>
                    <div className="drk">
                        <img
                            src={bannerImageFour}
                            alt="bannerImageFour"
                            style={{ width: '100%', height: 'calc(100vh - 100px)', objectFit: 'cover' }}
                        />
                    </div>
                </Carousel.Item>
            </Carousel>
        </div>
    );
}

export default Banner;