import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import staffing from '../../assets/staffing.jpg'
import '../../styles/home-page/home-staffing.scss'

function HomeStaffing() {
    return (
        <Container className='home-about-staffing'>
            <Row style={{ height: "100%" }}>
                <Col className='col' lg={6} md={12} sm={12} xs={12} xxs={12} style={{ height: "100%" }}>
                    <div className='left-side-image'>
                        <img src={staffing} alt='staffing' />
                    </div>
                </Col>
                <Col className='col' lg={6} md={12} sm={12} xs={12} xxs={12} style={{ height: "100%" }}>
                    <div className='right-side-image'>
                        <div className='content-main'>
                            <p className='heading'>IT Staffing Services</p>
                            <p className='content'>
                                Provide highly qualified resources for various IT skills in the US, both onshore and offshore (in India Delivery Center).
                            </p>
                            <p className='content'>
                                Will leverage an advanced AI infused Nliten Talent Acquistion (NTA) proprietary tool to screen and authenticate resource’s skills and capabilities for the target role description.
                            </p>
                            <p className='content'>
                                The combination of our NTA tool and resource selection process has significantly reduced the time and effort for our clients by more than 70%.
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default HomeStaffing