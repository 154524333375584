import React from 'react'
import consulting from '../../assets/consulting.jpg'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../styles/home-page/home-consulting.scss'

function HomeConsulting() {
    return (
        <Container className='home-about-consulting'>
            <Row style={{ height: "100%" }}>
                <Col className='col' lg={6} md={12} sm={12} xs={12} xxs={12} style={{ height: "100%" }}>
                    <div className='left-side-image' style={{ height: '400px' }}>
                        <img src={consulting} alt='consulting' />
                    </div>
                </Col>
                <Col className='col' lg={6} md={12} sm={12} xs={12} xxs={12} style={{ height: "100%" }}>
                    <div className='right-side-image'>
                        <div className='content-main'>
                            <p className='heading'>Data and AI Consulting</p>
                            <p className='content'>NlitenData provides strategy, design, implementation and run/manage services in the data and AI space</p>
                            <ul>
                                <li className='content'>Cloud lake house implementations and enhancements</li>
                                <li className='content'>Large scale data integrations for analytical, and AI platforms</li>
                                <li className='content'>Real time dashboards, advanced analytics, and ML models</li>
                                <li className='content'>Migrations from legacy DW/DL systems, mainframes & legacy applications to modern data ecosystems (based on data fabric, data mesh and other architectural constructs)</li>
                                <li className='content'>Data governance, data management, MDM, and data quality</li>
                                <li className='content'>AI/ML infusion for business automation, client productivity, and data management</li>
                            </ul>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default HomeConsulting