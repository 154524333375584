import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import industriesBanner from '../assets/industries-banner.jpg'
import industriesBanking from '../assets/industries-banking.jpg'
import industriesCapitalMarkets from '../assets/industries-capitalmarkets.jpg'
import industriesInsurance from '../assets/industries-insurance.jpg'
import industriesOilandgas from '../assets/industries-oilandgas.jpg'
import industriesUtilities from '../assets/industries-utilities.jpg'
import industriesRetail from '../assets/industries-retail.jpg'
import industriesHealthcare from '../assets/industries-healthcare.jpg'
import industriesManufacturing from '../assets/industries-manufacturing.jpg'
import industriesGovernment from '../assets/industries-government.jpg'
import '../styles/industries/industries.scss'

function Industries() {
    return (
        <div className='industries-component'>
            <div className='image-main'>
                <img className='about-img' src={industriesBanner} alt='industriesBanner' />
                <div className='overlay'></div>
                <p className='content'>We serve clients globally across industries</p>
            </div>
            <Container className='container'>
                <Row className='row'>
                    <Col className='col' lg={4} md={6} sm={12} xs={12} xxs={12}>
                        <Card className='card'>
                            <Card.Body className='card-body'>
                                <img src={industriesBanking} alt='industriesBanking' />
                                <div className='blur-area'>
                                    <p>Banking</p>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className='col' lg={4} md={6} sm={12} xs={12} xxs={12}>
                        <Card className='card'>
                            <Card.Body className='card-body'>
                                <img src={industriesCapitalMarkets} alt='industriesCapitalMarkets' />
                                <div className='blur-area'>
                                    <p>Capital Markets</p>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className='col' lg={4} md={12} sm={12} xs={12} xxs={12}>
                        <Card className='card'>
                            <Card.Body className='card-body'>
                                <img src={industriesInsurance} alt='industriesInsurance' />
                                <div className='blur-area'>
                                    <p>Insurance</p>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className='row'>
                    <Col className='col' lg={4} md={6} sm={12} xs={12} xxs={12}>
                        <Card className='card'>
                            <Card.Body className='card-body'>
                                <img src={industriesOilandgas} alt='industriesOilandgas' />
                                <div className='blur-area'>
                                    <p>Oil & Gas</p>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className='col' lg={4} md={6} sm={12} xs={12} xxs={12}>
                        <Card className='card'>
                            <Card.Body className='card-body'>
                                <img src={industriesUtilities} alt='industriesUtilities' />
                                <div className='blur-area'>
                                    <p>Utilities</p>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className='col' lg={4} md={12} sm={12} xs={12} xxs={12}>
                        <Card className='card'>
                            <Card.Body className='card-body'>
                                <img src={industriesRetail} alt='industriesRetail' />
                                <div className='blur-area'>
                                    <p>Retail</p>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className='row'>
                    <Col className='col' lg={4} md={6} sm={12} xs={12} xxs={12}>
                        <Card className='card'>
                            <Card.Body className='card-body'>
                                <img src={industriesHealthcare} alt='industriesHealthcare' />
                                <div className='blur-area'>
                                    <p>Healthcare & Life Sciences</p>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className='col' lg={4} md={6} sm={12} xs={12} xxs={12}>
                        <Card className='card'>
                            <Card.Body className='card-body'>
                                <img src={industriesManufacturing} alt='industriesHealthcare' />
                                <div className='blur-area'>
                                    <p>Manufacturing</p>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className='col' lg={4} md={12} sm={12} xs={12} xxs={12}>
                        <Card className='card'>
                            <Card.Body className='card-body'>
                                <img src={industriesGovernment} alt='industriesGovernment' />
                                <div className='blur-area'>
                                    <p>Government</p>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Industries;